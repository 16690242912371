.form-edit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .form-edit {
    padding: 10px 0px;
  }
}