.login__title {
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 29px;
  text-align: center;
  padding: 0;
  margin: 0 0 50px;
  color: #ffffff;
}