@font-face {
  src: url('../fonts/Inter-Regular.woff2'),
  url('../fonts/Inter-Regular.woff');
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src: url('../fonts/Inter-Medium.woff2'),
  url('../fonts/Inter-Medium.woff');
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
}

@font-face {
  src: url('../fonts/Inter-Black.woff2'),
  url('../fonts/Inter-Black.woff');
  font-family: 'Inter';
  font-weight: 900;
  font-style: normal;
}