.content {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 880px;
  padding: 40px 0px 67px 0px;
}

@media screen and (max-width: 900px) {
  .content {
    width: 680px;
    padding-top: 39px;
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 768px) {
  .content {
    width: 282px;
    padding-left: 19px;
    padding-right: 19px;
  }
}