.element__name {
  margin: 0;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  line-height: 1.20;
}

@media screen and (max-width: 768px) {
  .element__name {
    line-height: 1.29;
  }
}