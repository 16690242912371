.profile__edit-btn {
  flex: 0 1 24px;
  border: 1px solid #FFFFFF;
  border-radius: 0;
  width: 24px;
  height: 24px;
  margin-top: 18px;
  background-image: url('../../../images/profile/profile-edit-btn.svg');
  box-sizing: border-box;
  padding: 0;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: opacity .1s ease;
}

.profile__edit-btn:hover {
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  .profile__edit-btn {
    flex: 0 1 18px;
    margin-top: 10px;
    width: 18px;
    height: 18px;
  }
}