.profile__avatar {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.profile__avatar:hover + .profile__avatar-edit-btn {
  display: block;
}