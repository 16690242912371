.form-edit__title {
  margin: 0px 0px 54px 0px;
  padding: 0;
  max-width: 330px;
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  line-height: 1.20;
  white-space: nowrap;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .form-edit__title {
    margin: 0px 0px 55px 0px;
    font-size: 18px;
    line-height: 1.22;
  }
}