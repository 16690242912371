.header__element {
  margin: 6px 0 0;
  align-self: flex-start;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-decoration: none;
  transition: opacity .3s;
}

.header__element:hover {
  opacity: .6;
}

@media screen and (max-width: 768px) {
  .header__element {
    font-size: 14px;
    line-height: 17px;
  }
}