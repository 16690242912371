.popup-tooltip__image {
  position: relative;
  align-self: center;
  object-fit: contain;
  max-width: 120px;
  max-height: 120px;
  margin: 60px 155px 32px 155px;
}

@media screen and (max-width: 768px) {
  .popup-tooltip__image {
    margin: 50px 80px 40px 80px;
  }
}