.form__input-error {
  height: 12px;
  font-size: 10px;
  line-height: 1.25;
  color: #f00000;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  opacity: 0;
  transition: opacity .2s linear;
}
  
@media screen and (min-width: 768px) {
  .form__input-error {
    font-size: 12px;
  }
}