.profile__status {
  margin: 0;
  flex: 1 0 100%;
  padding: 0;
  width: 336px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.21;
  color: #FFFFFF;
}

@media screen and (max-width: 768px) {
  .profile__status {
    width: 282px;
    font-size: 14px;
    text-align: center;
  }
}