.login__container {
  max-width: 358px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 0 30px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}