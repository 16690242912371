.notfound__subtitle {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.21;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .notfound__subtitle {
    width: 282px;
    font-size: 14px;
    text-align: center;
  }
}