.form__field {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.form__field:nth-of-type(2) {
  margin: 40px 0px 0px 0px;
}