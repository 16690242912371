.navmenu {
  display: none;
}

@media screen and (max-width: 768px) {
  .navmenu {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
}