.form__error {
  margin: 30px 0 0 0;
  padding: 0;
  left: 0;
  top: 35%;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #f00000;
  opacity: 0;
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .form__error {
    margin-top: 15px;
  }
}