.form-delete__save-btn {
  box-sizing: border-box;
  width: 100%;
  border: none;
  outline: none;
  transition: backgroud-color .1s ease;
  text-align: center;
  color: #ffffff;
  border-radius: 2px;
  background-color: #000000;
}

.form-delete__save-btn:hover {
  background-color: rgba(0,0,0,.8);
}
