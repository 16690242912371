.notfound__error {
  width: 282;
  height: 282px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

