.profile__add-btn {
  align-self: center;
  width: 150px;
  height: 50px;
  border: 2px solid #FFFFFF;
  border-radius: 2px;
  background-image: url('../../../images/profile/profile-add-btn.svg');
  box-sizing: border-box;
  padding: 0;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: opacity .1s ease;
}

.profile__add-btn:hover {
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  .profile__add-btn {
    width: 282px;
  }
}
