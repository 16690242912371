.login__auth {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  text-decoration: none;
  transition: opacity .3s;
}

.login__auth:hover {
  opacity: .60;
}
