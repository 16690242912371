.page {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1280px;
  min-width: 300px;
  margin: 0 auto;
  background: #000000;
  font-family: 'Inter', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}