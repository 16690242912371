.notfound__title {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 2px;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .notfound__title {
    width: 189px;
    padding: 0px 10px 14px 26px;
    font-size: 18px;
    line-height: 1.22;
    text-align: center;
  }
}