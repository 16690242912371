.form-add__title {
  margin: 0px 36px 60px 36px;
  padding: 0;
  max-width: 358px;
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  line-height: 1.20;
  white-space: normal;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .form-add__title {
    margin: 0px 18px 50px 18px;
    font-size: 18px;
    line-height: 1.22;
  }
}